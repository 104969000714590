<template>
    <div class="card">
      <h2>Availability Settings</h2>
  
      <!-- Week Schedule -->
      <div class="schedule-container">
        <div v-for="day in weekDays" :key="day" class="day-schedule">
          <div class="day-header">
            <label class="switch-label">
              <span>{{ day }}</span>
              <div class="switch">
                <input
                  type="checkbox"
                  v-model="availability[day].enabled"
                  @change="updateAvailability"
                />
                <span class="slider"></span>
              </div>
            </label>
          </div>
  
          <div v-if="availability[day].enabled" class="time-slots">
            <div v-for="(timeSlot, index) in availability[day].timeSlots" 
                 :key="index" 
                 class="time-slot">
              <div class="time-inputs">
                <input
                  type="time"
                  v-model="timeSlot.start"
                  @change="updateAvailability"
                />
                <span>to</span>
                <input
                  type="time"
                  v-model="timeSlot.end"
                  @change="updateAvailability"
                />
              </div>
              <button 
                class="btn-remove"
                @click="removeTimeSlot(day, index)"
                aria-label="Remove time slot"
              >
                ×
              </button>
            </div>
            
            <button 
              class="btn-add"
              @click="addTimeSlot(day)"
            >
              Add Time Slot
            </button>
          </div>
        </div>
      </div>
  
      <!-- Save Changes -->
      <div class="save-section">
        <button 
          class="btn-save"
          @click="saveAvailability"
          :disabled="isSaving"
        >
          {{ isSaving ? 'Saving...' : 'Save Availability' }}
        </button>
      </div>
  
      <!-- Toast Message -->
      <div v-if="showToast" class="toast" :class="toastType">
        {{ toastMessage }}
      </div>
    </div>
  </template>
  
  <script>
  import { firestore } from '@/firebase';
  import { doc, updateDoc,getDoc } from "firebase/firestore";

  export default {
    name: 'PsychicAvailability',
    props: {
      psychicId: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        weekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        availability: {},
        isSaving: false,
        showToast: false,
        toastMessage: '',
        toastType: 'success',
      };
    },
    created() {
      this.initializeAvailability();
      this.fetchAvailability();
    },
    methods: {
      initializeAvailability() {
        this.weekDays.forEach(day => {
          this.availability[day] = {
            enabled: false,
            timeSlots: [
              { start: '09:00', end: '17:00' }
            ]
          };
        });
      },
      async fetchAvailability() {
        try {
            const userDocRef = doc(firestore, "users", this.psychicId);
            const docSnap = await getDoc(userDocRef);
            if (docSnap.exists()) {
                const data = docSnap.data();
                if (data.availability) {
                this.availability = { ...this.availability, ...data.availability };
                }
            }
            } catch (error) {
            this.showToastMessage("Error loading availability", "error");
        }
    },
        addTimeSlot(day) {
            const lastSlot = this.availability[day].timeSlots[this.availability[day].timeSlots.length - 1];
            this.availability[day].timeSlots.push({
            start: lastSlot.end,
            end: this.incrementTime(lastSlot.end)
        });
      },
      removeTimeSlot(day, index) {
        this.availability[day].timeSlots.splice(index, 1);
        if (this.availability[day].timeSlots.length === 0) {
          this.availability[day].timeSlots.push({ start: '09:00', end: '17:00' });
        }
        this.updateAvailability();
      },
      incrementTime(time) {
        const [hours, minutes] = time.split(':');
        const date = new Date();
        date.setHours(parseInt(hours));
        date.setMinutes(parseInt(minutes));
        date.setHours(date.getHours() + 1);
        return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
      },
      validateTimeSlots() {
        for (const day in this.availability) {
          if (this.availability[day].enabled) {
            const slots = this.availability[day].timeSlots;
            for (let i = 0; i < slots.length; i++) {
              if (slots[i].start >= slots[i].end) {
                this.showToastMessage(`Invalid time slot in ${day}`, 'error');
                return false;
              }
              if (i > 0 && slots[i].start <= slots[i-1].end) {
                this.showToastMessage(`Overlapping time slots in ${day}`, 'error');
                return false;
              }
            }
          }
        }
        return true;
      },
      async saveAvailability() {
       if (!this.validateTimeSlots()) return;
            this.isSaving = true;
        try {
            const userDocRef = doc(firestore, "users", this.psychicId);
            await updateDoc(userDocRef, {
                availability: this.availability,
            });
            this.showToastMessage("Availability saved successfully", "success");
            } catch (error) {
            this.showToastMessage("Error saving availability", "error");
            } finally {
            this.isSaving = false;
        }
    },
      showToastMessage(message, type = 'success') {
        this.toastMessage = message;
        this.toastType = type;
        this.showToast = true;
        setTimeout(() => {
          this.showToast = false;
        }, 3000);
      },
      updateAvailability() {
        // Triggered on any change to availability
        // You could implement auto-save here if desired
      }
    }
  };
  </script>
  
  <style scoped>
  .card {
    background: #2a093a;
    padding: 1.5rem;
    border-radius: 12px;
    margin: 1rem 0;
  }
  
  .schedule-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin: 1rem 0;
  }
  
  .day-schedule {
    border: 1px solid #9b4bc0;
    border-radius: 8px;
    padding: 1rem;
  }
  
  .day-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .switch-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #ecc3ff;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #3d0d57;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #ecc3ff;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #ffcd00;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .time-slots {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .time-slot {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  
  .time-inputs {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-grow: 1;
  }
  
  .time-inputs input {
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #9b4bc0;
    background-color: #3d0d57;
    color: #ecc3ff;
  }
  
  .time-inputs span {
    color: #ecc3ff;
  }
  
  .btn-remove {
    padding: 0.25rem 0.75rem;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-add {
    padding: 0.5rem;
    background-color: #9b4bc0;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 0.5rem;
  }
  
  .btn-save {
    width: 100%;
    padding: 1rem;
    background-color: #ffcd00;
    color: #2a093a;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 1rem;
  }
  
  .btn-save:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .toast {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 1rem;
    border-radius: 4px;
    color: white;
    z-index: 1000;
  }
  
  .toast.success {
    background-color: #4caf50;
  }
  
  .toast.error {
    background-color: #f44336;
  }
  </style>