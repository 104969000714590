<template>
  <div class="dashboard">
    <SideBarModule @navigate="navigateToPage" />
    <div class="content">
      <component :is="currentComponent" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import DashboardModule from './DashboardModule.vue';
import MessagesModule from './MessagesModule.vue';
import SettingsModule from './SettingsModule.vue';
import CalendarModule from './CalendarModule.vue';
import SideBarModule from './SideBarModule.vue';

export default {
  name: 'AppDashboard',
  components: {
    DashboardModule,
    SideBarModule,
    MessagesModule,
    SettingsModule,
    CalendarModule,
  },
  computed: {
    ...mapState(['currentPage']),
    currentComponent() {
      return {
        DashboardModule,
        MessagesModule,
        SettingsModule,
        CalendarModule,
      }[this.currentPage];
    },
  },
  methods: {
    ...mapMutations(['setCurrentPage']),
    navigateToPage(page) {
      this.setCurrentPage(page);
    },
  },
};
</script>

<style scoped>

html, body {
  margin: 0;
  padding: 0;
  height: 100%; /* Full height */
  overflow: hidden; /* Prevent page scrolling */
}

.dashboard {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.sidebar {
  width: 300px;
  background-color: #16041E;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 0 5px #DB8FFF;
  border-right: 2px solid #DB8FFF;
  z-index: 1000;
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
}

.content {
  padding-left: 300px;
  flex-grow: 1;
  overflow-y: auto;
  overflow: hidden; /* Prevent scroll here as well */
}

@media (max-width: 768px) {
  .dashboard {
    flex-direction: column;
    width: 100%;
    min-height: 100vh; /* Ensures full scrolling area */
    margin: 0; /* Remove margin to avoid gaps */
    overflow-x: hidden;
  }

  .sidebar {
    width: 100%;
    height: 60px; /* Set height for the bottom sidebar */
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    z-index: 999;
    background-color: #2a093a; /* Ensures visible background */
  }

  .content {
    width: 100%; /* Full width */
    min-height: calc(100vh - 600px); /* Height excluding the fixed sidebar height */
    padding-left: 0px;
    padding-right: 16px;
    margin: 0; /* Remove margin to avoid gaps */
    box-sizing: border-box;
    overflow-y: auto; /* Enables scrolling */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}


</style>