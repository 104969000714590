import { createApp } from 'vue'; // Import createApp from Vue
import App from './App.vue'; // Import your main App component
import router from './router'; // Import the router instance
import { VueFire, VueFireAuth } from 'vuefire'; // Import VueFire and VueFireAuth for Firebase integration
import { auth, firestore } from './firebase'; // Import your Firebase auth and firestore instances
import store from './store/index.js';

// Import Vuetify and styles (for Vuetify 3)
import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

// Create Vuetify instance with a custom theme
const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: '#6a1b9a',  // OpenPsychic Purple
          secondary: '#9c27b0',
          background: '#f5f5f5',
          surface: '#ffffff',
        },
      },
    },
  },
});

// Initialize the Vue app
const app = createApp(App);

// Make Firebase services available in all components
app.config.globalProperties.$auth = auth;
app.config.globalProperties.$firestore = firestore;

// Set up Firebase Auth and Firestore using VueFire
app.use(VueFire, {
  auth,        // Using Firebase Auth
  firestore,   // Using Firebase Firestore
  modules: [VueFireAuth],  // Include VueFireAuth module for Firebase Authentication
});

// Use the router instance with the app
app.use(router);

// Use Vuetify with the app
app.use(vuetify);

app.use(store).mount('#app');
