<template>
  <div id="app">
    <template v-if="!isHomePage">
      <!-- Standard layout -->
      <router-view />
    </template>

    <template v-else>
      <!-- Full-page home layout -->
      <router-view />
    </template>
  </div>
</template>

<script>
import './styles/global-reset.css';

export default {
  name: 'App',
  computed: {
    isHomePage() {
      return this.$route.meta.isHomePage;
    },
  },
};
</script>

<style>
/* Global Reset */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevent scrolling */
  box-sizing: border-box;
  background-color: #16041E; /* Background color */

}

/* App Container */
#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  background-color: #16041E; /* Background color */
  color: #690099; /* Text color */
  font-family: Avenir, Helvetica, Arial, sans-serif;
  overflow: hidden; /* Prevent scrolling */
}

/* Standard layout container */
.main-container {
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevent scrolling */
}
</style>
