<template>
  <div class="popup-card-content" v-if="showModal">
    <span class="close" @click="closeModal">&times;</span>
    <h2>Send Private Message</h2>

    <div class="form">
      <textarea v-model="message" class="form-input" placeholder="Type your message..."></textarea>
      <div class="button-container">
        <button @click="sendMessage" class="submit-btn" :disabled="!isFormValid">Send Message</button>
        <button @click="cancelMessage" class="cancel-btn">Cancel</button> <!-- Cancel button is always enabled -->
      </div>
    </div>
  </div>

  <!-- Toast Notification -->
  <div v-if="showToast" :class="['toast', toastType]">{{ toastMessage }}</div>
</template>
<script>
import { doc, serverTimestamp, collection, addDoc, writeBatch } from "firebase/firestore";
import { firestore } from "@/firebase";
import { getAuth } from "firebase/auth";

export default {
  props: {
    psychic: {
      type: Object,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showModal: true,
      message: "",
      showToast: false,
      toastMessage: "",
      toastType: "success",
    };
  },
  computed: {
    isFormValid() {
      return this.message.trim() !== '';  // Check if message is not empty
    }
  },
  methods: {
    async sendMessage() {
      if (!this.message.trim()) return;

      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (!currentUser) return;

      const senderId = currentUser.uid;
      const receiverId = this.psychic.id;

      const senderThreadDoc = doc(firestore, `users/${senderId}/messages/${receiverId}`);
      const receiverThreadDoc = doc(firestore, `users/${receiverId}/messages/${senderId}`);

      const messageData = {
        message: this.message.trim(),
        timestamp: serverTimestamp(),
        senderid: senderId,
        status: "sent"
      };

      try {
        const batch = writeBatch(firestore);

        // Ensure threads exist with metadata
        batch.set(senderThreadDoc, { lastupdate: serverTimestamp(), lastMessage: this.message.trim() }, { merge: true });
        batch.set(receiverThreadDoc, { lastupdate: serverTimestamp(), lastMessage: this.message.trim(), unread: true }, { merge: true });

        // Add message to both threads
        await Promise.all([
          addDoc(collection(firestore, `users/${senderId}/messages/${receiverId}/messages`), messageData),
          addDoc(collection(firestore, `users/${receiverId}/messages/${senderId}/messages`), messageData)
        ]);

        await batch.commit();

        this.showToastMessage("Message Sent", "success");
        this.message = "";
        setTimeout(this.closeModal, 1000);
      } catch (error) {
        this.showToastMessage("Failed to send message", "error");
        console.error(error);
      }
    },
    cancelMessage() {
      this.message = ""; // Clear message content
      this.closeModal(); // Close the modal
    },
    showToastMessage(message, type) {
      this.toastMessage = message;
      this.toastType = type;
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 2000);
    },
  },
};
</script>

  
  <style scoped>
   
.submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #ffcd00;
  color: #2a093a;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.cancel-btn {
  width: 100%;
  padding: 12px;
  background-color: #9b4bc0;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 10px;
}

  .popup-card-content {
    width: 100%;
    max-width: 400px;
    font-family: 'Inter', sans-serif;
    margin: 8px auto;
    border-radius: 8px;
    color: #ECC3FF;
    height: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 16px;
    box-sizing: border-box;
    max-height: 80vh;
    animation: fadeIn 0.3s ease-in-out;
    background: rgba(22, 4, 30, 0.9);
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .form-input {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #9b4bc0;
    background-color: #3d0d57;
    color: #ecc3ff;
    font-size: 1em;
    box-sizing: border-box;
    margin-bottom: 4px;
  }
  
  .toast {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 12px 24px;
    border-radius: 8px;
    color: white;
    font-weight: 500;
    z-index: 1000;
  }
  
  .toast.error {
    background-color: #ff4444;
  }
  
  .toast.success {
    background-color: #00C851;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  </style>
  