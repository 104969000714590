<template>
  <div :class="['sidebar', { collapsed: isCollapsed, 'is-mobile': isMobile, 'visible': isSidebarVisible }]">
    <nav class="nav-menu">
      <ul>
        <li v-for="item in menuItems" :key="item.label" @click="navigate(item.component)">
          <img :src="item.icon" alt="Icon" class="nav-icon" />
          <span v-if="!isCollapsed || !isMobile" class="nav-label">{{ item.label }}</span>
        </li>
      </ul>
    </nav>
    
    <button class="toggle-button" @click="toggleCollapse">
      <span class="toggle-icon"></span>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: window.innerWidth <= 600,  // Updated max width to 600px
      menuItems: [
        { label: 'Dashboard', component: 'DashboardModule', icon: require('@/assets/home-agreement.png') },
        { label: 'Messages', component: 'MessagesModule', icon: require('@/assets/mail-account.png') },
        { label: 'Calendar', component: 'CalendarModule', icon: require('@/assets/data.png') },
        { label: 'Settings', component: 'SettingsModule', icon: require('@/assets/001-setting-bulb.png') },
      ],
    };
  },
  methods: {
    navigate(component) {
      this.$emit('navigate', component);
      if (this.isMobile) {
        this.isCollapsed = true;
      }
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
      this.$emit('collapsed', this.isCollapsed);
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 600;  // Updated to 600px
      if (!this.isMobile) {
        this.isCollapsed = false;
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style scoped>
.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 300px;
  height: 100vh;
  background-color: #16041E;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  transition: all 0.3s ease;
}

.nav-menu {
  margin-top: 16px;
  flex-grow: 1;
}

.nav-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.nav-menu li {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.nav-menu li:hover {
  background-color: #50108a;
}

.nav-icon {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.nav-label {
  font-size: 16px;
  color: #ECC3FF;
}

.toggle-button {
  display: none;
}

/* Mobile Styles */
@media (max-width: 600px) {  /* Changed to 600px */
  .sidebar {
    top: auto;
    bottom: 0;
    width: 100%;
    height: 60px;
    flex-direction: row;
    align-items: center;
  }

  .nav-menu {
    margin-top: 0;
    width: 100%;
  }

  .nav-menu ul {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  }

  .nav-menu li {
    padding: 8px 12px;
    flex-direction: column;
    justify-content: center;
    height: auto;
  }

  .nav-icon {
    width: 20px;
    height: 20px;
    margin: 0 0 4px 0;
  }

  .nav-label {
    font-size: 12px;
    text-align: center;
  }

  .collapsed {
    height: 0;
    padding: 0;
    overflow: hidden;
  }

  .toggle-button {
    position: absolute;
    top: -20px;
    right: 20px;
    width: 40px;
    height: 4px;
    background-color: #16041E;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block;
  }

  .toggle-button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 4px;
    background-color: #ECC3FF;
    border-radius: 2px;
  }
}
</style>
