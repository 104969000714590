import { createStore } from 'vuex'; // Correct Vuex import for Vue 3

const store = createStore({
  state: {
    userData: null,
    userPass: null,
    currentPage: 'MessagesModule',
    psychic: null,
  },
  mutations: {
    setUserData(state, userData) {
      state.userData = userData;
    },
    setUserPass(state, userPass) {
      state.userPass = userPass;
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setPsychic(state, psychic) {
      state.psychic = psychic;
    },
  },
  actions: {
    setUserData({ commit }, userData) {
      commit('setUserData', userData);
    },
    setUserPass({ commit }, userPass) {
      commit('setUserPass', userPass);
    },
    navigateToMessages({ commit }, psychic) {
      commit('setCurrentPage', 'MessagesModule');
      commit('setPsychic', psychic);
    },
  },
  getters: {
    getUserData: (state) => state.userData,
    getUserPass: (state) => state.userPass,
    getPsychic: (state) => state.psychic,
  },
});

export default store;
