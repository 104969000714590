<template>
  <!-- Section Title -->
  <div class="psychic-list">
    <PsychicCard
      v-for="psychic in psychics"
      :key="psychic.id"
      :psychic="psychic"
      class="psychic-item"
      @click="selectPsychic(psychic)"
    />
  </div>
</template>

<script>
import PsychicCard from '@/dashboard/PsychicCard.vue';

export default {
  name: 'PsychicModule',
  components: {
    PsychicCard,
  },
  props: {
    psychics: {
      type: Array,
      required: true,
    },
  },
  methods: {
    selectPsychic(psychic) {
      this.$emit('select', psychic); // Emit selected psychic
    }
  }
};
</script>
<style scoped>
.psychic-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Start items from the left */
  gap: 16px;
  max-height: 120vh; /* Adjust this for smaller screens */
  overflow-y: scroll;
  padding: 8px;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.psychic-list::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.psychic-item {
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: calc(33.33% - 20px); /* 3 cards per row by default */
  box-sizing: border-box;
  cursor: pointer;
  transition: transform 0.2s;
  flex: 1 0 auto; /* Ensures the card will stretch but not shrink */
  min-width: 280px; /* Prevent squishing when there's only one card */
}

.psychic-item:hover {
  transform: translateY(-5px);
}

/* Responsive design adjustments */
/* On medium screens, display 1 card per row */
@media (max-width: 768px) {
  .psychic-item {
    width: calc(100% - 20px);
    min-width: 320px; /* Ensures the card won't become too small */
  }
}

/* On small screens, also display 1 card per row */
@media (max-width: 480px) {
  .psychic-item {
    width: calc(100% - 20px);
    min-width: 280px;
  }
}

</style>
