<template>
  <div class="messages-module">
    <div class="settings-header">
      <h1>Messages</h1>
      <div v-if="isLoading" class="loading-indicator">Loading...</div>
    </div>

    <div class="content-card">
      <!-- Message List -->
      <div v-if="!selectedThread" class="message-list">
        <div v-if="messages.length === 0 && !isLoading" class="empty-messages">
          No messages yet
        </div>
        <div
          v-else
          v-for="message in messages"
          :key="message.id"
          class="message-card"
          @click="selectThread(message)"
          :class="{ 'unread': message.unread }"
        >
          <div class="message-header">
            <div class="sender-info">
              <img 
                :src="message.psychicData?.profileimgsrc || placeholderImage" 
                :alt="message.psychicData?.username || 'Default Avatar'" 
                class="sender-avatar"
                @error="handleImageError" 
              />
              <span class="sender-name">{{ message.psychicData?.displayName || message.psychicData?.username || 'Loading...' }}</span>
            </div>
            <span class="message-time">{{ formatDate(message.threadData?.lastupdate) }}</span>
          </div>
          <div class="message-body">
            <p>{{ message.threadData?.lastMessage || '' }}</p>
          </div>
        </div>
        
        <div v-if="hasMoreMessages" class="load-more" @click="loadMoreMessages">
          Load More Messages
        </div>
      </div>

      <!-- Thread View -->
      <div v-else class="thread-view">
        <div class="thread-header">
          <button class="back-btn" @click="closeThread" aria-label="Return to messages">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M19 12H5M12 19l-7-7 7-7"/>
            </svg>
            Back
          </button>
          <div class="psychic-info" v-if="selectedThread?.psychicData">
            <img 
              v-if="selectedThread.psychicData.profileimgsrc" 
              :src="selectedThread.psychicData.profileimgsrc || placeholderImage" 
              :alt="selectedThread.psychicData.username"
              class="psychic-avatar"
              @error="handleImageError"
            />
            <h2>{{ selectedThread.psychicData.displayName || selectedThread.psychicData.username }}</h2>
          </div>
        </div>

        <div 
          class="thread-messages" 
          ref="messagesContainer"
          @scroll="handleThreadScroll"
        >
          <div v-if="isLoadingHistory" class="loading-indicator">Loading history...</div>
          
          <div
            v-for="msg in threadMessages"
            :key="msg.id"
            class="thread-message"
          >
            <div
              class="thread-message-body"
              :class="{
                'message-right': msg.senderid === currentUser?.uid,
                'message-left': msg.senderid !== currentUser?.uid
              }"
            >
              <div class="message-bubble">
                <p>{{ msg.message }}</p>
                <span class="message-time">{{ formatDate(msg.timestamp) }}</span>
                <span class="message-status" v-if="msg.senderid === currentUser?.uid">
                  {{ msg.status }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="selectedThread" class="message-input">
      <input
        v-model="newMessage"
        @keyup.enter="sendMessage"
        placeholder="Type a message..."
        :disabled="isSending"
        aria-label="Message input"
      />
      <button 
        class="send-btn" 
        @click="sendMessage" 
        :disabled="!newMessage.trim() || isSending"
        aria-label="Send message"
      >
        <span v-if="isSending">Sending...</span>
        <span v-else>Send</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch} from 'vue';
import { auth, firestore } from '@/firebase';
import { useStore } from 'vuex';
import { setDoc, getDocs, writeBatch } from 'firebase/firestore';
import { 
  collection, 
  addDoc, 
  onSnapshot, 
  query, 
  orderBy, 
  doc, 
  getDoc,
  limit,
  startAfter,
  updateDoc,
  serverTimestamp 
} from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import placeholderImage from '@/assets/openpsychiclogo.png'; // Correct import if path is correct

// State
const currentUser = ref(null);
const messages = ref([]);
const selectedThread = ref(null);
const threadMessages = ref([]);
const newMessage = ref('');
const isLoading = ref(false);
const isSending = ref(false);
const isLoadingHistory = ref(false);
const hasMoreMessages = ref(true);
const lastVisible = ref(null);
const unsubscribers = ref([]);
const psychicCache = new Map();
const messagesContainer = ref(null);
const store = useStore();
const MESSAGES_PER_PAGE = 20;

const handleImageError = (event) => {
  event.target.src = placeholderImage; // Fallback to placeholder if image fails to load
};

// Methods
const formatDate = (timestamp) => {
  if (!timestamp) return 'Invalid date';
  const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
  return new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  }).format(date);
};

const fetchPsychicData = async (psychicId) => {
  if (!psychicId) return null;
  
  // Check cache first
  if (psychicCache.has(psychicId)) {
    return psychicCache.get(psychicId);
  }

  try {
    const psychicRef = doc(firestore, 'users', psychicId);
    const psychicDoc = await getDoc(psychicRef);

    if (psychicDoc.exists()) {
      const psychicData = {
        id: psychicDoc.id,
        ...psychicDoc.data(),
        lastFetched: Date.now()
      };
      
      // Cache the result
      psychicCache.set(psychicId, psychicData);
      return psychicData;
    }
    
    console.warn(`No psychic found with ID: ${psychicId}`);
    return null;
  } catch (error) {
    console.error('Error fetching psychic data:', error);
    return null;
  }
};

const fetchThreads = async (isLoadingMore = false) => {
  if (!currentUser.value || isLoading.value) return;
  
  try {
    isLoading.value = true;
    const messagesRef = collection(firestore, 'users', currentUser.value.uid, 'messages');
    let q = query(
      messagesRef,
      orderBy('lastupdate', 'desc'),
      limit(MESSAGES_PER_PAGE)
    );

    if (isLoadingMore && lastVisible.value) {
      q = query(q, startAfter(lastVisible.value));
    }

    const snapshot = await getDocs(q);
    
    if (snapshot.empty) {
      hasMoreMessages.value = false;
      return;
    }

    lastVisible.value = snapshot.docs[snapshot.docs.length - 1];
    
    const newMessages = await Promise.all(
      snapshot.docs.map(async (doc) => {
        const messageData = doc.data();
        const psychicData = await fetchPsychicData(doc.id);
        
        return {
          id: doc.id,
          threadData: messageData,
          psychicData,
        };
      })
    );

    if (isLoadingMore) {
      messages.value = [...messages.value, ...newMessages];
    } else {
      messages.value = newMessages;
    }

  } catch (error) {
    console.error('Error fetching threads:', error);
  } finally {
    isLoading.value = false;
  }
};

const loadMoreMessages = () => {
  if (!hasMoreMessages.value || isLoading.value) return;
  fetchThreads(true);
};

const fetchThreadMessages = async (threadId, isLoadingMore = false) => {
  if (!currentUser.value || !threadId || isLoadingHistory.value) return;
  
  try {
    isLoadingHistory.value = true;
    const threadMessagesRef = collection(
      firestore,
      'users',
      currentUser.value.uid,
      'messages',
      threadId,
      'messages'
    );

    let q = query(
      threadMessagesRef,
      orderBy('timestamp', 'desc'),
      limit(MESSAGES_PER_PAGE)
    );

    if (isLoadingMore && threadMessages.value.length > 0) {
      const lastMessage = threadMessages.value[threadMessages.value.length - 1];
      q = query(q, startAfter(lastMessage.timestamp));
    }

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newMessages = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      if (isLoadingMore) {
        threadMessages.value = [...threadMessages.value, ...newMessages];
      } else {
        threadMessages.value = newMessages;
      }
    });

    unsubscribers.value.push(unsubscribe);

  } catch (error) {
    console.error('Error fetching thread messages:', error);
  } finally {
    isLoadingHistory.value = false;
  }
};

const selectThread = async (thread) => {
  if (!thread || selectedThread.value?.id === thread.id) return;
  
  try {
    // Clear previous messages
    threadMessages.value = [];
    
    // Update thread data
    const threadData = thread.threadData || {};
    const userThreadRef = doc(firestore, 'users', currentUser.value.uid, 'messages', thread.id);
    
    // Mark as read
    if (threadData.unread) {
      await updateDoc(userThreadRef, {
        unread: false,
        lastReadAt: serverTimestamp()
      });
    }

    // Set selected thread with psychic data
    selectedThread.value = {
      ...thread,
      psychicData: thread.psychicData || await fetchPsychicData(thread.id)
    };

    // Fetch messages
    await fetchThreadMessages(thread.id);
    
    // Scroll to bottom after messages load
    setTimeout(() => {
      if (messagesContainer.value) {
        messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
      }
    }, 100);

  } catch (error) {
    console.error('Error selecting thread:', error);
  }
};

const sendMessage = async () => {
  if (!currentUser.value || !selectedThread.value || !newMessage.value.trim() || isSending.value) return;

  isSending.value = true;
  const messageText = newMessage.value.trim();
  newMessage.value = ''; // Clear input immediately for better UX

  try {
    const timestamp = serverTimestamp();
    const messageData = {
      senderid: currentUser.value.uid,
      message: messageText,
      status: 'sent',
      timestamp
    };

    // Update both user and psychic thread documents
    const batch = writeBatch(firestore);
    
    // User's thread
    const userThreadRef = doc(firestore, 'users', currentUser.value.uid, 'messages', selectedThread.value.id);
    batch.update(userThreadRef, {
      lastupdate: timestamp,
      lastMessage: messageText
    });

    // Psychic's thread
    const psychicThreadRef = doc(firestore, 'users', selectedThread.value.id, 'messages', currentUser.value.uid);
    batch.update(psychicThreadRef, {
      lastupdate: timestamp,
      lastMessage: messageText,
      unread: true
    });

    // Add message to both threads
    await Promise.all([
      addDoc(collection(userThreadRef, 'messages'), messageData),
      addDoc(collection(psychicThreadRef, 'messages'), messageData)
    ]);

    await batch.commit();

  } catch (error) {
    console.error('Error sending message:', error);
    newMessage.value = messageText; // Restore message on error
  } finally {
    isSending.value = false;
  }
};

const handleThreadScroll = () => {
  if (!messagesContainer.value) return;
  
  const { scrollTop,} = messagesContainer.value;
  if (scrollTop === 0) {
    fetchThreadMessages(selectedThread.value.id, true);
  }
};

const closeThread = () => {
  selectedThread.value = null;
  threadMessages.value = [];
  // Cleanup thread-specific listeners
  unsubscribers.value.forEach(unsubscribe => unsubscribe());
  unsubscribers.value = [];
};

// Lifecycle
onMounted(() => {
  const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
    currentUser.value = user;
    if (user) {
      fetchThreads();
    } else {
      messages.value = [];
      selectedThread.value = null;
      threadMessages.value = [];
    }
  });

  unsubscribers.value.push(unsubscribeAuth);
});

onMounted(() => {
  const unsubscribeStore = store.subscribeAction(({ type, payload }) => {
    if (type === 'navigateToMessages' && payload?.id) {
      // Set selectedThread based on the payload.id (the thread ID)
      selectedThread.value = {
        id: payload.id,
        // Optionally, include any other thread-related data from payload
        threadData: {}, // Replace with actual thread data if available

      };
      // Optionally call the createThread function to ensure the thread exists
      createThread(payload.userid);
    }
  });

  // Push the unsubscribe function into unsubscribers to clean up later
  unsubscribers.value.push(unsubscribeStore);
});

onUnmounted(() => {
  unsubscribers.value.forEach(unsubscribe => unsubscribe());
  unsubscribers.value = [];
});

const createThread = async (threadId) => {
  if (!currentUser.value || !threadId) return; // Ensure the user is authenticated and threadId is provided

  try {
    const userThreadRef = doc(firestore, 'users', currentUser.value.uid, 'messages', threadId);
    const psychicThreadRef = doc(firestore, 'users', threadId, 'messages', currentUser.value.uid);

    // Check if the user's thread exists
    const userThreadDoc = await getDoc(userThreadRef);
    if (!userThreadDoc.exists()) {
      // Create a new thread for the user if it doesn't exist, with createdAt and lastupdate
      await setDoc(userThreadRef, { createdAt: new Date(), lastupdate: new Date() }, { merge: true });
      console.log('User thread created');
    }

    // Check if the psychic's thread exists
    const psychicThreadDoc = await getDoc(psychicThreadRef);
    if (!psychicThreadDoc.exists()) {
      // Create a new thread for the psychic if it doesn't exist, with createdAt and lastupdate
      await setDoc(psychicThreadRef, { createdAt: new Date(), lastupdate: new Date() }, { merge: true });
      console.log('Psychic thread created');
    }

  } catch (error) {
    console.error('Error creating thread:', error);
  }
};

// Watch for changes to keep scroll at bottom for new messages
watch(threadMessages, () => {
  if (messagesContainer.value) {
    messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
  }
}, { deep: true });
</script>

<style scoped>
.messages-module {
  width: 100vh;
  font-family: 'Inter', sans-serif;
  margin: 8px;
  padding: 20px;
  background-color: #2a093a;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  color: #ECC3FF;
  height: calc(100vh - 40px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.messages-module::-webkit-scrollbar {
  display: none;
}

.messages-module {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.settings-header h1 {
  font-size: 28px;
  font-weight: 700;
  color: #ECC3FF;
}

.content-card {
  background-color: #3f1e4e;
  border-radius: 10px;
  padding: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;

}

.message-list {
  overflow-y: auto;
  flex-grow: 1;
  padding: 8px;
}

.empty-messages {
  text-align: center;
  color: #9a6f9c;
  font-size: 16px;
  padding: 40px;
}

.message-card {
  background: rgba(243, 231, 255, 0.95);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 12px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.message-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.sender-name {
  font-weight: 600;
  color: #6a3b87;
}

.message-time {
  font-size: 12px;
  color: #9a6f9c;
}

.message-body p {
  margin: 0;
  color: #4a2e4d;
  line-height: 1.4;
}

.thread-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.thread-view h2 {
  font-size: 22px;
  font-weight: 700;
  color: #ECC3FF;
  margin: 16px 0;
}

.thread-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px 0;
}

.thread-messages::-webkit-scrollbar {
  display: none; /* Hide scrollbar Chrome, Safari, Opera */
}

.thread-message {
  margin-bottom: 16px;
}

.thread-message-body {
  display: flex;
}

.message-bubble {
  max-width: 70%;
  padding: 12px 16px;
  border-radius: 16px;
  position: relative;
  word-break: break-word;
}

.message-right {
  justify-content: flex-end;
}

.message-right .message-bubble {
  background: #4a2e4d;
  color: white;
  border-bottom-right-radius: 4px;
}

.message-left {
  justify-content: flex-start;
}

.message-left .message-bubble {
  background: rgba(242, 209, 255, 0.95);
  color: #2a093a;
  border-bottom-left-radius: 4px;
}

.message-input {
  display: flex;
  gap: 12px;
  padding: 16px;
  background-color: #3f1e4e;
  border-radius: 12px;
  margin-top: auto;
}

.message-input input {
  flex-grow: 1;
  padding: 12px 16px;
  font-size: 14px;
  border: 1px solid #D1A0F1;
  border-radius: 8px;
  outline: none;
  transition: border 0.3s ease;
}

.message-input input:focus {
  border-color: #6200ea;
}

.back-btn {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 18px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  background: #F2D1FF;
  color: #2a093a;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
}

.back-btn:hover {
  background: #D1A0F1;
}

.update-btn {
  background-color: #ECC3FF;
  color: #2a093a;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s ease;
}

.update-btn:hover {
  background-color: #D79CFF;
  transform: scale(1.05);
}

.update-btn:focus {
  outline: none;
}

.update-btn:active {
  background-color: #BE86D6;
}

.update-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;  
}

/* Animations */
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: #ECC3FF;
}

.sender-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sender-avatar,
.psychic-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

@media (max-width: 600px) {
  .messages-module {
    width: 100%; /* Full width */
    height: auto;
    overflow-y: auto; /* Enables scrolling */
  }

  .content-card {
    padding: 12px;
    margin-bottom: 16px;
  }

  .message-list {
    padding: 4px;
  }

  .empty-messages {
    font-size: 14px;
    padding: 20px;
  }

  .message-card {
    padding: 12px;
  }

  .message-input {
    padding: 12px;
  }
}
</style>
