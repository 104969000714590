<template>
  <div class="card">
      <div class="main-content">
          <div class="category-wheel" ref="categoryWheel">
              <div
                  v-for="(category, index) in categories"
                  :key="index"
                  class="category-item"
                  @click="selectCategory(category.label)"
              >
                  <div class="category-icon">
                      <img :src="category.icon" :alt="category.label" />
                  </div>
                  <div class="category-label">
                      {{ category.label }}
                  </div>
              </div>
          </div>

          <div v-if="noPsychicsAvailable" class="no-psychics-message">
              There are currently no psychics available. Please check another category.
          </div>

          <div class="expanded-psychic-module-wrapper">
              <PsychicModule
                  v-if="!noPsychicsAvailable && !selectedPsychic"
                  :psychics="filteredPsychics"
                  @select="selectPsychic"
              />
          </div>

          <div class="expanded-card-wrapper">
              <PsychicExpandedCard
                  v-if="selectedPsychic"
                  :psychic="selectedPsychic"
                  @back="deselectPsychic"
                  class="full-width-card"
              />
          </div>
      </div>
  </div>
</template>

<script>
import { firestore } from '@/firebase'
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import PsychicModule from '@/dashboard/PsychicModule.vue';
import PsychicExpandedCard from '@/dashboard/PsychicExpandedCard.vue';
import { EventBus } from '../Eventbus.js';

export default {
  components: {
      PsychicModule,
      PsychicExpandedCard,
  },
  mounted() {
      EventBus.on('search', this.updateSearchQuery);
      this.fetchAllPsychics();
  },
  beforeUnmount() {
      EventBus.off('search', this.updateSearchQuery);
  },
  data() {
      return {
          categories: [
              { label: "All", icon: require('@/assets/magic_wand.png') },
              { label: "General Readings", icon: require('@/assets/category_icon_turban.png') },
              { label: "Tarot Readings", icon: require('@/assets/category_icon_tarot_card.png') },
              { label: "Lingering Spirits", icon: require('@/assets/category_icon_spirit.png') },
              { label: "Fortune Telling", icon: require('@/assets/category_icon_magic_ball.png') },
              { label: "Palm Readings", icon: require('@/assets/category_icon_palm.png') },
              { label: "Astrology", icon: require('@/assets/category_icon_saturn.png') },
              { label: "Dream Interpretations", icon: require('@/assets/category_icon_dream.png') },
              { label: "Past Lives", icon: require('@/assets/category_icon_ghost.png') },
              { label: "Love & Relationships", icon: require('@/assets/category_icon_heart.png') }
          ],
          selectedPsychic: null,
          psychics: [],
          noPsychicsAvailable: false,
          searchQuery: '',
          selectedCategory: 'All'
      };
  },
  computed: {
      filteredPsychics() {
          const normalizedQuery = this.searchQuery.toLowerCase().trim();
          if (!normalizedQuery) {
              return this.psychics;
          }

          const queryWords = normalizedQuery.split(/\s+/);
          return this.psychics.filter(psychic => {
              const userName = psychic.userName?.toLowerCase() || '';
              const firstName = psychic.firstName?.toLowerCase() || '';
              const lastName = psychic.lastName?.toLowerCase() || '';
              const fullName = `${firstName} ${lastName}`;

              return queryWords.some(queryWord =>
                  userName.includes(queryWord) ||
                  firstName.includes(queryWord) ||
                  lastName.includes(queryWord) ||
                  fullName.includes(queryWord)
              );
          });
      },
  },
  methods: {
      updateSearchQuery(query) {
          this.searchQuery = query;
      },
      async selectCategory(category) {
          this.selectedCategory = category;
          if (category === "All") {
              await this.fetchAllPsychics();
          } else {
              await this.fetchCategoryPsychics(category);
          }
          this.selectedPsychic = null;
      },
      async fetchUserData(userId) {
          try {
              const userRef = doc(firestore, 'users', userId);
              const userDoc = await getDoc(userRef);
              
              if (!userDoc.exists()) {
                  console.warn(`No user found with ID: ${userId}`);
                  return null;
              }

              const userData = userDoc.data();
              return {
                  id: userId,
                  profileImage: userData.profileimgsrc || "",
                  name: userData.displayname || "",
                  bio: userData.bio || "",
                  ratings: userData.psychicrating || null,
                  userType: userData.usertype || "",
                  userName: userData.username || "",
                  displayImgSrc: userData.displayimgsrc || "",
                  email: userData.email || "",
                  firstName: userData.firstname || "",
                  lastName: userData.lastname || "",
                  premium: userData.premium || false,
                  isOnline: userData.isOnline || false,
                  lastOnline: userData.lastOnline ? userData.lastOnline.toDate().toLocaleString() : 'Never',
                  psychicOnDisplay: userData.psychicondisplay || false,
                  psychicOnDisplayCategory: userData.psychicondisplaycategory || "",
                  psychicOriginCountry: userData.psychicorigincountry || null,
                  psychicOriginYear: userData.psychicoriginyear || null,
                  questionsAnswered: userData.questionsAnswered || 0,
                  questionsAsked: userData.questionsAsked || 0,
                  requestCount: userData.requestcount || 0
              };
          } catch (error) {
              console.error("Error fetching user data:", error);
              return null;
          }
      },
      async fetchCategoryPsychics(category) {
          try {
              const psychicsCollection = collection(firestore, `psychicOnDisplay/${category}/psychicsOnDisplay`);
              const snapshot = await getDocs(psychicsCollection);

              if (snapshot.empty) {
                  console.warn("No psychics found in this category.");
                  this.noPsychicsAvailable = true;
                  this.psychics = [];
                  return;
              }

              // Get all psychic IDs from the category
              const psychicIds = snapshot.docs.map(doc => doc.id);
              
              // Fetch full user data for each psychic ID
              const psychicDataPromises = psychicIds.map(id => this.fetchUserData(id));
              const psychicData = await Promise.all(psychicDataPromises);
              
              // Filter out any null results (failed fetches)
              this.psychics = psychicData.filter(data => data !== null);
              this.noPsychicsAvailable = this.psychics.length === 0;
          } catch (error) {
              console.error("Error fetching psychics:", error);
              this.noPsychicsAvailable = true;
          }
      },
      async fetchAllPsychics() {
    try {
        // Fetch users collection from Firestore
        const usersCollection = collection(firestore, 'users');
        // Query to filter users where usertype equals "psychic"
        const q = query(usersCollection, where('usertype', '==', 'psychic'));
        const snapshot = await getDocs(q);

        if (snapshot.empty) {
            console.warn("No psychics found");
            this.noPsychicsAvailable = true;
            this.psychics = [];
            return;
        }

        // Map through the documents and format the data
        this.psychics = snapshot.docs.map(doc => {
            const data = doc.data();
            return {
                id: doc.id,
                profileImage: data.profileimgsrc || "",
                name: data.displayname || "",
                bio: data.bio || "",
                ratings: data.psychicrating || null,
                userType: data.usertype || "",
                userName: data.username || "",
                displayImgSrc: data.displayimgsrc || "",
                email: data.email || "",
                firstName: data.firstname || "",
                lastName: data.lastname || "",
                premium: data.premium || false,
                isOnline: data.isOnline || false,
                lastOnline: data.lastOnline ? data.lastOnline.toDate().toLocaleString() : 'Never',
                psychicOnDisplay: data.psychicondisplay || false,
                psychicOnDisplayCategory: data.psychicondisplaycategory || "",
                psychicOriginCountry: data.psychicorigincountry || null,
                psychicOriginYear: data.psychicoriginyear || null,
                questionsAnswered: data.questionsAnswered || 0,
                questionsAsked: data.questionsAsked || 0,
                requestCount: data.requestcount || 0
            };
        });

        this.noPsychicsAvailable = this.psychics.length === 0;
    } catch (error) {
        console.error("Error fetching psychics:", error);
        this.noPsychicsAvailable = true;
    }
},
      selectPsychic(psychic) {
          this.selectedPsychic = psychic;
      },
      deselectPsychic() {
          this.selectedPsychic = null;
      }
  }
};
</script>
<style scoped>

.settings-header h1 {
  padding: 8px;
  font-size: 28px;
  font-weight: 700;
  color: #ECC3FF;
}
/* Wrapper for expanded psychic module */
.expanded-psychic-module-wrapper,
.expanded-card-wrapper {
  max-height: 80vh; /* Limit height for better scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100%;
  display: flex;
  justify-content: flex-start; /* Start items from the left */
  padding: 0;
  margin: 0;
  -ms-overflow-style: none; /* IE/Edge */
  scrollbar-width: none; /* Firefox */
  
}

.expanded-psychic-module-wrapper::-webkit-scrollbar,
.expanded-card-wrapper::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome, Safari, Opera */
}

/* Full-width card */
.full-width-card {
  width: 100%;
  max-width: 100%; /* Ensure no size restrictions */
  box-sizing: border-box;
}

/* Card styling */
.card {
  width: 100vh;
  font-family: 'Inter', sans-serif;
  margin: 8px;
  background-color: #2a093a;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  color: #ECC3FF;
  height: calc(100vh - 40px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding:16px;
}

/* Main content container */
.main-content {

}

/* 'No Psychics Available' message */
.no-psychics-message {
  padding: 16px;
  text-align: center;
}

/* Logo styling */
.logo img {
  height: 50px;
  max-width: 100%;
}

/* Profile bubble styles */
.profile-bubble {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-bubble img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Contact Wheel Styles */
.contact-wheel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  position: relative;
  overflow-x: auto;
  scrollbar-width: none;
}

.contact-wheel {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  padding: 0;
  max-width: 100%;
  -ms-overflow-style: none;
}

.contact-wheel::-webkit-scrollbar {
  display: none;
}

.contact-item {
  flex: 0 0 auto;
  margin: 0 10px;
  text-align: center;
}

.contact-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #6a0dad;
}

.scroll-btn {
  background: none;
  border: none;
  color: #6a0dad;
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.left-btn {
  left: 10px;
}

.right-btn {
  right: 10px;
}

.scroll-btn:hover {
  color: #50108a;
}

/* Category Wheel */
.category-wheel {
  display: flex;
  justify-content: space-evenly;
  gap: 8px;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  scrollbar-width: none;
}

.category-wheel::-webkit-scrollbar {
  display: none;
}

.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  font-size: 12px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  transition: background-color 0.3s;
}

.category-item:hover {
  background-color: #50108a;
}

.category-icon {
  margin-bottom: 4px; /* Space between icon and label */
}

.category-icon img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .card {
    height: auto;
    width: 100%;
  }

  .logo img {
    height: 40px;
  }

  .category-item {
    padding: 5px 8px;
    font-size: 11px;
  }

  .contact-image {
    width: 50px;
    height: 50px;
  }
}
</style>
