<template>
  <div class="card">
    <div class="calendar-container">
      <FullCalendar 
        :options="calendarOptions" 
        ref="fullCalendar"
      />
    </div>

    <div v-if="showModal" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <h3>Appointment Details</h3>
        <p><strong>Title:</strong> {{ eventDetails.title }}</p>
        <p><strong>Status:</strong> {{ eventDetails.status }}</p>
        <p><strong>Description:</strong> {{ eventDetails.description }}</p>
        <p><strong>Email:</strong> {{ eventDetails.clientEmail }}</p>
        <p><strong>Username:</strong> {{ eventDetails.username }}</p>
        <p><strong>Preferred Contact Method:</strong> {{ eventDetails.preferredContactMethod }}</p>
        <p><strong>Preferred Time Zone:</strong> {{ eventDetails.preferredTimeZone }}</p>
        <p><strong>Payment Method:</strong> {{ eventDetails.paymentMethod }}</p>
        <p><strong>Session Length:</strong> {{ eventDetails.sessionLength }}</p>
        <p><strong>Created At:</strong> {{ eventDetails.createdAt }}</p>
        <button @click="closeModal">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import { firestore } from "@/firebase"; // Assuming firebase is initialized in 'firebase.js'
import { getAuth } from 'firebase/auth';
import { getDocs, collection } from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore'; // Make sure Timestamp is imported

export default {
  components: {
    FullCalendar,
  },
  data() {
    return {
      showModal: false,
      eventDetails: {},
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: "dayGridMonth",
        dateClick: this.onDateClick,
        eventClick: this.onEventClick,
        events: this.getAppointmentsFromFirestore,
        contentHeight: 'auto', // Allow the content to adjust to fit within the height
      },
    };
  },
  methods: {
    onEventClick(info) {
      // Open the modal and show event details
      this.eventDetails = {
        title: info.event.title,
        status: info.event.extendedProps.status,
        description: info.event.extendedProps.description,
        clientEmail: info.event.extendedProps.clientEmail,
        username: info.event.extendedProps.username,
        preferredContactMethod: info.event.extendedProps.preferredContactMethod,
        preferredTimeZone: info.event.extendedProps.preferredTimeZone,
        paymentMethod: info.event.extendedProps.paymentMethod,
        sessionLength: info.event.extendedProps.sessionLength,
        createdAt: info.event.extendedProps.createdAt,
      };

      this.showModal = true; // Show the modal
    },

    async getAppointmentsFromFirestore(fetchInfo, successCallback, failureCallback) {
    try {
      // Get current user's UID
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not logged in');
      }

      const uid = user.uid;

      // Fetch appointments for the current user from the sub-collection under 'users/{uid}/appointments'
      const appointmentsRef = collection(firestore, `users/${uid}/appointments`);
      const querySnapshot = await getDocs(appointmentsRef);

      const events = [];

      querySnapshot.forEach(doc => {
        const eventData = doc.data();

        // Adjust the 'date' to a JavaScript Date object and one day backwards
        const eventDate = eventData.date instanceof Timestamp
          ? new Date(eventData.date.seconds * 1000) // Convert Firestore Timestamp to Date
          : new Date(eventData.date); // If it's already a Date object

        // Subtract one day from the event date (if needed)
        eventDate.setDate(eventDate.getDate());

        // Construct event using the new booking object structure
        const event = {
          title: `${eventData.type} with ${eventData.firstName} ${eventData.lastName}`,
          start: eventDate.toISOString(), // FullCalendar expects an ISO string for start
          description: eventData.description || 'No description', // Default to 'No description' if empty
          client: eventData.firstName + ' ' + eventData.lastName, // Client full name
          type: eventData.type,
          status: eventData.status,
          clientEmail: eventData.email, // Email from the booking object
          username: eventData.username, // Username
          preferredContactMethod: eventData.preferredContactMethod,
          preferredTimeZone: eventData.preferredTimeZone || 'N/A', // Default to 'N/A' if empty
          paymentMethod: eventData.paymentMethod,
          sessionLength: eventData.sessionLength,
          psychicId: eventData.psychicId,
          createdAt: eventData.createdAt ? new Date(eventData.createdAt.seconds * 1000).toLocaleString() : '', // Format timestamp
        };

        events.push(event);
      });

      successCallback(events); // Return events to FullCalendar
    } catch (error) {
      console.error("Error fetching events from Firestore:", error);
      failureCallback(error); // Trigger failure callback
    }
  },
    // Format the createdAt timestamp into a readable string
    formatTimestamp(timestamp) {
      if (timestamp instanceof Date) {
        return timestamp.toLocaleString(); // If it's a native JavaScript Date object
      }
      return timestamp ? new Date(timestamp.seconds * 1000).toLocaleString() : ''; // For Firestore Timestamp
    },

    // Method to set the booking date one day backwards
    setBookingDate() {
      const bookingDate = this.selectedBookingDate instanceof Timestamp
        ? this.selectedBookingDate
        : Timestamp.fromDate(new Date(new Date(this.selectedBookingDate).setDate(new Date(this.selectedBookingDate).getDate() - 1)));

      console.log("Booking date set to:", bookingDate);
    },

    closeModal() {
      // Close the modal
      this.showModal = false;
    },
  },
};
</script>`
<style scoped>
/* Card styling */
.card {
  width: 100vh;
  font-family: 'Inter', sans-serif;
  margin: 8px;
  background-color: #2a093a;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  color: #ECC3FF;
  height: calc(100vh - 40px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}


/* Calendar container styling */
.calendar-container {
  padding: 20px;
  width: 100%;
  height: 100%;
}

/* Modal Overlay Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 10px;
}

/* Modal Content Styling */
.modal-content {
  background-color: #2a093a;
  color: #ecc3ff;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 10px;
}

.modal-content h3 {
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.modal-content button {
  padding: 10px 20px;
  background-color: #690099;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 15px;
  width: 100%;
}

.modal-content button:hover {
  background-color: #16041E;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .card {
    width: 100%; /* Full width */
    height: auto; /* Full height minus nav bar */
  }

  .modal-content {
    max-width: 100%;
  }

  .modal-content h3 {
    font-size: 1rem;
  }

  .modal-content button {
    font-size: 0.9rem;
  }

  .calendar-container {
    padding: 16px;
    overflow: hidden;

  }
}
</style>