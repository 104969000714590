<template>
  <div class="popup-card-content">
    <span class="close" @click="closeModal">&times;</span>
    <h2>Reserve Your Booking</h2>

    <div class="form">
      <!-- Booking Time Selection Section -->
      <div class="booking-time-section">
        <!-- Available Dates -->
        <div class="form-group full-width">
          <label class="form-label">Book Your Appointment</label>
          <select 
            v-model="selectedDate" 
            class="form-input" 
            @change="fetchAvailableTimes"
          >
            <option value="">Select a date</option>
            <option v-for="(day, index) in availableDates" :key="index" :value="day">
              {{ day }} <!-- Display the name of the day -->
            </option>
          </select>
          
        </div>

        <!-- Available Times -->
        <div class="form-group full-width" v-if="selectedDate">
          <select 
            v-model="selectedTime" 
            class="form-input"
          >
            <option value="">Select a time</option>
            <option v-for="(time, index) in availableTimes" :key="index" :value="time.start">
              {{ formatTime(time.start) }} - {{ formatTime(time.end) }}
            </option>
          </select>
        </div>

        <!-- Date Selection Based on Enabled Days -->
        <div class="form-group full-width">
          <select v-model="selectedBookingDate" class="form-input">
            <option value="">Select a date</option>
            <option v-for="date in filteredDates" :key="date" :value="date">
              {{ formatDateWithDay(date) }}
            </option>
          </select>
        </div>

        <!-- Custom Time Picker -->
        <div class="form-group full-width" v-if="selectedDate">
          <input 
            type="time" 
            v-model="customTime" 
            class="form-input"
            :min="minTime" 
            :max="maxTime" 
            required 
            @input="validateCustomTime"
          />
          <p v-if="timeError" class="error-message">
            Please select a time between {{ formatTime(minTime) }} and {{ formatTime(maxTime) }}
          </p>
        </div>        
        <p v-if="availableDates.length === 0" class="no-dates-message">Yikes! This Psychic's Availability is Not Set.</p>

      </div>

      <!-- Rest of the form fields -->
      <input v-model="username" type="text" placeholder="Username" disabled class="form-input" />
      <input v-model="firstName" type="text" placeholder="First Name" class="form-input" />
      <input v-model="lastName" type="text" placeholder="Last Name" class="form-input" />
      <input v-model="email" type="email" placeholder="Your Email" disabled class="form-input" />

      <div class="form-group">
        <label class="form-label">Preferred Method of Contact</label>
        <select v-model="preferredContactMethod" class="form-input">
          <option value="email">Email</option>
          <option value="phone">Phone</option>
          <option value="message">In-App Message</option>
        </select>
      </div>

      <div class="form-group">
        <label class="form-label">Preferred Time Zone</label>
        <input 
          v-model="preferredTimeZone" 
          type="text" 
          placeholder="Enter Time Zone (e.g., GMT+2)" 
          class="form-input"
        />
      </div>

      <div class="form-group">
        <label class="form-label">Method of Payment</label>
        <select v-model="paymentMethod" class="form-input">
          <option value="offline">Offline</option>
          <option value="paypal">PayPal</option>
          <option value="bank">Bank Transfer</option>
        </select>
      </div>

      <div class="form-group">
        <label class="form-label">Session Length</label>
        <select v-model="sessionLength" class="form-input">
          <option value="30min">30 minutes</option>
          <option value="1hr">1 hour</option>
          <option value="2hr">2 hours</option>
        </select>
      </div>

      <textarea 
        v-model="notes" 
        placeholder="Additional Notes" 
        class="form-input"
      ></textarea>

      <label class="checkbox-label">
        <input type="checkbox" v-model="termsConfirmed" />
        <span>I confirm that I understand the terms and conditions.</span>
      </label>

      <button 
        class="submit-btn" 
        @click="submitBooking" 
        :disabled="!isFormValid || timeError"
      >
        Confirm Booking
      </button>
      <button class="cancel-btn" @click="closeModal">Cancel</button>
    </div>

    <div v-if="showToast" class="toast" :class="toastType">
      {{ toastMessage }}
    </div>
  </div>
   <!-- Confirmation Popup -->
   <div v-if="showConfirmationPopup" class="popup-card">
      <div class="popup-content">
        <h3>Your reservation has been successfully confirmed!</h3>
        <button @click="closeConfirmationPopup">Close</button>
      </div>
    </div>
</template>

<script>
import { firestore } from "@/firebase";
import { doc, getDoc, collection, addDoc } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';

export default {
  props: {
    psychic: {
      type: Object,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showModal: true,
      showConfirmationPopup: false,  // New property for managing the confirmation popup
      selectedDate: '',
      selectedTime: '',
      customTime: '',
      enabledDays: [], // To store the enabled days fetched from the backend
      availableDates: [],
      availableTimes: [],
      firstName: '',
      lastName: '',
      email: '',
      username: '',
      userid: '',
      preferredContactMethod: 'email',
      preferredTimeZone: '',
      paymentMethod: 'offline',
      sessionLength: '30min',
      notes: '',
      termsConfirmed: false,
      showToast: false,
      toastMessage: '',
      toastType: 'info',
      timeError: false,
      minTime: "09:00",  // Set minimum time to 9:00 AM
      maxTime: "17:00",  // Set maximum time to 5:00 PM
    };
  },
  computed: {
    isFormValid() {
      return (
        this.selectedDate &&
        (this.selectedTime || this.customTime) &&
        this.firstName &&
        this.lastName &&
        this.email &&
        this.termsConfirmed &&
        !this.timeError
      );
    }
  },
  methods: {
    showConfirmationModal() {
    this.showConfirmationPopup = true;
  },
  closeConfirmationPopup() {
    this.showConfirmationPopup = false;
  },
     formatDateWithDay(dateStr) {
      const date = new Date(dateStr);
      return date.toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    },
    generateSelectableDates() {
      this.filteredDates = [];
      const today = new Date();

      // Shift enabled days to the next day
      const shiftedEnabledDays = this.enabledDays.map(day => {
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const currentIndex = daysOfWeek.indexOf(day);  // Get index of the current day
        const nextIndex = (currentIndex + 1) % 7;  // Get the next day, using modulo to wrap around
        return daysOfWeek[nextIndex];
    });

    console.log("Shifted Enabled Days:", shiftedEnabledDays);  // Debug log

    // Loop through 60 days and check if the current day matches the shifted enabled days
    for (let i = 0; i < 60; i++) {  // Start from 1 to skip today
      const currentDate = new Date(today);
      currentDate.setDate(today.getDate() + i);

      const dayName = currentDate.toLocaleDateString(undefined, { weekday: 'long' });

      // Only include dates matching the shifted enabled days
      if (shiftedEnabledDays.includes(dayName)) {
        this.filteredDates.push(currentDate.toISOString().split('T')[0]);
      }
    }
  },
    formatDate(dateStr) {
      return new Date(dateStr).toLocaleDateString();
    },
    formatTime(timeStr) {
      return new Date(`2000-01-01T${timeStr}`).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      });
    },
    async fetchProfileData() {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        const uid = user.uid;
        const userDocRef = doc(firestore, "users", uid);
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          this.firstName = data.firstName || '';
          this.lastName = data.lastName || '';
          this.email = data.email || '';
          this.username = '@'+data.username || '';
          this.userid = data.userid;
        }
      } catch (error) {
        this.showToastMessage("Error loading user profile data", "error");
      }
    },
    async fetchAvailableDates() {
      try {
        const userDocRef = doc(firestore, "users", this.psychic.id);
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          this.availableDates = Object.keys(data.availability || {})
            .filter(day => data.availability[day]?.enabled); // Filter enabled days
          this.enabledDays = this.availableDates;
          this.generateSelectableDates(); // Generate dates based on enabled days

        }
      } catch (error) {
        this.showToastMessage("Error loading available dates", "error");
      }
    },
    async fetchAvailableTimes() {
      if (!this.selectedDate) return;
      try {
        const userDocRef = doc(firestore, "users", this.psychic.id);
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          const selectedDay = data.availability?.[this.selectedDate];
          
          if (selectedDay) {
            this.availableTimes = selectedDay.timeSlots || [];
            this.minTime = selectedDay.startTime || "09:00";  // Default to 9:00 AM
            this.maxTime = selectedDay.endTime || "17:00";    // Default to 5:00 PM
            this.customTime = ''; // Reset custom time when changing date
            this.timeError = false;
          }
        }
      } catch (error) {
        this.showToastMessage("Error loading available times", "error");
      }
    },
    validateCustomTime() {
      // Check if the custom time is within the allowed range
      if (this.customTime) {
        const isWithinRange = this.customTime >= this.minTime && this.customTime <= this.maxTime;
        this.timeError = !isWithinRange;
      } else {
        this.timeError = false;
      }
    },
    showToastMessage(message, type = 'info') {
      this.toastMessage = message;
      this.toastType = type;
      this.showToast = true;
      setTimeout(() => (this.showToast = false), 3000);
    },
    submitBooking() {
      if (!this.isFormValid) {
        this.showToastMessage("Please complete all required fields.", "error");
        return;
      }

      const formattedTime = `${this.customTime}:00`; // Append ":00" to make it HH:mm:ss

      // Create a Date object using the selectedBookingDate and formatted time
      const combinedDateString = `${this.selectedBookingDate}T${formattedTime}`;
      const combinedDate = new Date(combinedDateString);

      // Subtract one day from the selectedBookingDate
      combinedDate.setDate(combinedDate.getDate() - 1);

      // Convert the final date to Firestore Timestamp
      const bookingDate = Timestamp.fromDate(combinedDate);

      const booking = {
        date: bookingDate,
        time: this.customTime,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        username: this.username,
        userid: this.userid,
        preferredContactMethod: this.preferredContactMethod,
        preferredTimeZone: this.preferredTimeZone,
        paymentMethod: this.paymentMethod,
        sessionLength: this.sessionLength,
        description: this.notes,
        psychicId: this.psychic.id,
        createdAt: new Date(),
        status: 'pending',
        type: "Reservation"
      };

      // Define references to both users' appointments
      const clientUserId = this.userid;  // Replace with actual client user ID
      const psychicUserId = this.psychic.id;  // Replace with psychic user ID

      // Firestore references for both users
      const clientAppointmentsRef = collection(firestore, 'users', clientUserId, 'appointments');
      const psychicAppointmentsRef = collection(firestore, 'users', psychicUserId, 'appointments');

      // Use Promise.all to ensure both writes happen concurrently
      Promise.all([
        addDoc(clientAppointmentsRef, booking),
        addDoc(psychicAppointmentsRef, booking)
      ])
        .then(() => {
         
          this.showToastMessage("Your booking has been submitted!", "success");
          // // Show a success message
          // Wait 1 second before closing modal and showing confirmation
          setTimeout(() => {
            // First, show the confirmation modal
            this.showConfirmationModal();

            // Then close the original modal
            this.closeModal();
          }, 1000);
        })
        .catch(error => {
          console.error("Error adding booking to appointments: ", error);
          this.showToastMessage("There was an error submitting your booking.", "error");
        });
    },
  },
  mounted() {
    this.fetchProfileData(); // Fetch user profile data
    this.fetchAvailableDates(); // Fetch available dates
    this.generateSelectableDates();
  }
};
</script>


<style scoped>
.no-dates-message{
  padding-top: 8px;
}

.popup-card-content {
  width: 100%;
  max-width: 400px;
  font-family: 'Inter', sans-serif;
  margin: 8px auto;
  border-radius: 8px;
  color: #ECC3FF;
  height: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px;
  box-sizing: border-box;
  max-height: 80vh;
  animation: fadeIn 0.3s ease-in-out;
  background: rgba(22, 4, 30, 0.9);
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.booking-time-section {
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid #9b4bc0;
  padding-bottom: 20px;
}

.form-group {
  width: 100%;
}

.form-group.full-width {
  width: 100%;
}

.form-label {
  display: block;
  margin-bottom: 8px;
  color: #ffcd00;
  font-weight: 500;
}

.form-input {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #9b4bc0;
  background-color: #3d0d57;
  color: #ecc3ff;
  font-size: 1em;
  box-sizing: border-box;
  margin-bottom: 8px;
}

.error-message {
  color: #ff4444;
  font-size: 0.9em;
  margin-top: 4px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 15px 0;
}

.submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #ffcd00;
  color: #2a093a;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.cancel-btn {
  width: 100%;
  padding: 12px;
  background-color: #9b4bc0;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 10px;
}

.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 24px;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  z-index: 1000;
}

.toast.error {
  background-color: #ff4444;
}

.toast.success {
  background-color: #00C851;
}

.toast.info {
  background-color: #33b5e5;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
</style>