<template>
  <div class="flex items-center justify-center min-h-screen bg-gradient-to-r from-purple-600 to-indigo-800 text-white">
    <div class="text-center">
      <h1 class="text-4xl font-extrabold mb-4">Redirecting to Our Donation Page...</h1>
      <p class="text-lg font-medium mb-6">
        Thank you for supporting OpenPsychic! Please hold on, we're taking you there securely.
      </p>
      <!-- Centered Spinner -->
      <div class="w-16 h-16 border-4 border-t-transparent border-white rounded-full animate-spin mx-auto"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DonateRedirect',
  mounted() {
    // Redirect after 3 seconds
    setTimeout(() => {
      window.location.href = 'https://donate.stripe.com/cN29ALf154tfgveeUY';
    }, 3000);
  },
};
</script>

<style scoped>
/* Spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.animate-spin {
  animation: spin 1s linear infinite;
}
</style>
