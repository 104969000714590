<template>
  <div class="popup-card-content">
    <span class="close" @click="closeModal">&times;</span>
    <h2>Request a Reading</h2>

    <div class="form">
      <!-- Section 1: Request Type -->
      <section>
        <label class="form-label">Request Type</label>
        <div class="form-group full-width">
          <select v-model="requestType" class="form-input">
            <option v-for="category in categories" :key="category.label" :value="category.label.toLowerCase().replace(/ & /g, '_').replace(/ /g, '_')">
              {{ category.label }}
            </option>
          </select>
        </div>
      </section>

      <!-- Section 2: Energy Focus -->
      <section>
        <label class="form-label">Energy Focus</label>
        <div class="form-group">
          <input v-model="energyFocus" type="text" placeholder="What is your focus? (e.g. Love, Career)" class="form-input" />
        </div>
      </section>

      <!-- Section 3: Preferred Reading Method -->
      <section>
        <label class="form-label">Preferred Reading Method</label>
        <div class="form-group full-width">
          <select v-model="preferredReadingMethod" class="form-input">
            <option value="tarot_cards">Tarot Cards</option>
            <option value="astrology">Astrology</option>
            <option value="numerology">Numerology</option>
          </select>
        </div>
      </section>

      <!-- Section 4: Specific Question -->
      <section>
        <label class="form-label">Your Question</label>
        <div class="form-group">
          <input v-model="specificQuestion" placeholder="What's your question?" class="form-input"> 
        </div>
      </section>

      <!-- Section 5: User Information -->
      <section>
        <label class="form-label">Your Information</label>
        <input v-model="username" type="text" placeholder="Username" class="form-input" disabled />
        <input v-model="firstName" type="text" placeholder="First Name" class="form-input" />
        <input v-model="lastName" type="text" placeholder="Last Name" class="form-input" />
        <input v-model="email" type="email" placeholder="Email" class="form-input" disabled />
        <div class="form-group">
        <input type="date" v-model="dateOfBirth" class="form-input" placeholder="Select your date of birth" />
      </div>
      </section>

      <!-- Section 6: Additional Notes -->
      <section>
        <label class="form-label">Additional Details</label>
        <div class="form-group">
          <textarea v-model="message" placeholder="Give as many details as you'd like..." class="form-input"></textarea>
        </div>
      </section>

      <!-- Section 7: Confirmation -->
      <section>
        <div class="form-group">
          <label class="checkbox-label">
            <input type="checkbox" v-model="opentoinsights" />
            <span>Are you open to insights?</span>
          </label>
        </div>
      </section>

      <!-- Submit and Cancel Buttons -->
      <section>
        <button class="submit-btn" @click="submitRequest" :disabled="!isFormValid">
          Submit Request
        </button>
        <button class="cancel-btn" @click="closeModal">Cancel</button>
      </section>
    </div>

    <div v-if="showToast" class="toast" :class="toastType">
      {{ toastMessage }}
    </div>
  </div>

  <!-- Confirmation Popup -->
  <div v-if="showConfirmationPopup" class="popup-card">
    <div class="popup-content">
      <h3>Your request has been successfully submitted!</h3>
      <button @click="closeConfirmationPopup">Close</button>
    </div>
  </div>
</template>

  <script>
  import { auth, firestore } from "@/firebase";
  import { doc, getDoc, setDoc } from "firebase/firestore";
  
  export default {
    props: {
      psychic: {
        type: Object,
        required: true,
      },
      closeModal: {
        type: Function,
        required: true,
      },
    },
    data() {
      return {
        message: '',
        dateOfBirth: '',  // This will hold the selected DOB
        preferredReadingMethod: "tarot_cards", // Default value for Preferred Reading Method
        showModal: true,
        showConfirmationPopup: false,
        energyFocus: '', // Energy Focus field
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        specificQuestion: '',
        opentoinsights: false,
        showToast: false,
        toastMessage: '',
        toastType: 'info',
        requestType: 'general_readings', // Default value for the dropdown
        categories: [
          { label: "General Readings" },
          { label: "Tarot Readings" },
          { label: "Lingering Spirits" },
          { label: "Fortune Telling" },
          { label: "Palm Readings" },
          { label: "Astrology" },
          { label: "Dream Interpretations" },
          { label: "Past Lives" },
          { label: "Love & Relationships" }
        ]
      };
    },
    computed: {
      isFormValid() {
        return (
          this.firstName &&
          this.lastName &&
          this.email &&
          this.username &&
          this.specificQuestion&&
          this.dateOfBirth &&
          this.energyFocus
        );
      }
    },
    methods: {
      async fetchProfileData() {
        try {
          const userid = auth.currentUser.uid;
          const userDocRef = doc(firestore, "users", userid);
          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            this.firstName = data.firstName || '';
            this.lastName = data.lastName || '';
            this.email = data.email || '';
            this.username = '@' + data.username || '';
            this.userid = data.userid;
          }
        } catch (error) {
          this.showToastMessage("Error loading user profile data", "error");
        }
      },
      showToastMessage(message, type = 'info') {
        this.toastMessage = message;
        this.toastType = type;
        this.showToast = true;
        setTimeout(() => (this.showToast = false), 3000);
      },
      submitRequest() {
        if (!this.isFormValid) {
            this.showToastMessage("Please complete all required fields.", "error");
            return;
        }

        const dobTimestamp = new Date(this.dateOfBirth).getTime(); // Convert to timestamp
        const userid = auth.currentUser.uid;
        const requestId = `${userid}_${this.psychic.id}_${new Date().getTime()}`; // Create a unique requestId

        const request = {
            dateOfBirth: dobTimestamp,  // Save DOB as a timestamp
            requestType: this.requestType,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            userName: this.username,
            specificQuestion: this.specificQuestion,
            energyFocus: this.energyFocus, // Include Energy Focus field
            preferredReadingMethod: this.preferredReadingMethod, // Include Preferred Reading Method
            receiverId: this.psychic.id,
            senderId: userid,
            message: this.message,
            createdAt: new Date(),
            requestStatus: 'sent',
            requestId, // Set the requestId for easy reference
        };
   
        // Reference for the user's request collection at the requestId
        const clientRequestDocRef = doc(firestore, 'users', userid, 'request', this.psychic.id, 'request', requestId);

        // Reference for the psychic's specific request collection at the requestId
        const psychicRequestDocRef = doc(firestore, 'users', this.psychic.id, 'request', userid, 'request', requestId);

         setDoc(clientRequestDocRef, request)
            .then(() => {
            // Add request to psychic's request collection
            return setDoc(psychicRequestDocRef, request);
            })
            .then(() => {
            this.showToastMessage("Your request has been submitted!", "success");
            setTimeout(() => {
                this.showConfirmationModal();
                this.closeModal();
            }, 1000);
            })
            .catch(error => {
            console.error("Error adding request: ", error);
            this.showToastMessage("There was an error submitting your request.", "error");
            });
        },
      showConfirmationModal() {
        this.showConfirmationPopup = true;
      },
      closeConfirmationPopup() {
        this.showConfirmationPopup = false;
      },
    },
    mounted() {
      this.fetchProfileData(); // Fetch user profile data
    }
  };
  </script>
  
  <style scoped>
  
  .popup-card-content {
    width: 100%;
    max-width: 400px;
    font-family: 'Inter', sans-serif;
    border-radius: 8px;
    color: #ECC3FF;
    height: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 16px;
    box-sizing: border-box;
    max-height: 80vh;
    animation: fadeIn 0.3s ease-in-out;
    background: rgba(22, 4, 30, 0.9);
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .form-group {
    width: 100%;
  }
  
  .form-group.full-width {
    width: 100%;
  }
  
  .form-label {
    display: block;
    margin-bottom: 8px;
    color: #ffcd00;
    font-weight: 500;
  }
  
  .form-input {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #9b4bc0;
    background-color: #3d0d57;
    color: #ecc3ff;
    font-size: 1em;
    box-sizing: border-box;
    margin-bottom: 8px;
  }
  
  .error-message {
    color: #ff4444;
    font-size: 0.9em;
    margin-top: 4px;
  }
  
  .checkbox-label {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 16px 0;
    margin-top: -4px;
  }
  
.submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #ffcd00;
  color: #2a093a;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.cancel-btn {
  width: 100%;
  padding: 12px;
  background-color: #9b4bc0;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 10px;
}

  
  .toast {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ff4444;
    padding: 10px;
    border-radius: 8px;
    color: white;
    font-size: 1em;
    display: none;
  }
  
  .toast.info {
    background-color: #3b8fba;
  }
  
  .toast.success {
    background-color: #4caf50;
  }
  </style>
  