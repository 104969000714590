<template>
  <div 
    class="page-container swipe-container" 
    @touchstart="handleTouchStart" 
    @touchend="handleTouchEnd"
  >
    <div class="psychic-profile-card">
      <!-- Header Section -->
      <div class="profile-header">
        <img :src="psychic.displayImgSrc || placeholderImage" alt="Profile Background" class="background-image" @error="handleImageError" />
        <div class="profile-info">
          <img :src="psychic.profileImage || placeholderImage" alt="Psychic Image" class="profile-image" @error="handleImageError" />
        </div>
      </div>

      <!-- Bio and Rating Section -->
      <div class="profile-body">
        <h2 class="name">{{ psychic.name || 'Unknown Psychic' }}</h2>
        <p class="username">{{ '@' + (psychic.userName || 'User') }}</p>
        <p class="bio">{{ psychic.bio || 'No bio available.' }}</p>
      </div>

      <!-- Contact Module -->
      <div class="contact-card">
        <!-- Contact Header -->
        <h3>Contact</h3>

        <!-- Call-to-Action Buttons -->
        <button @click="onRequest" class="contact-btn call-btn">Make Reading Requst</button>
        <button @click="onMessage" class="contact-btn message-btn">Send Private Message</button>
        <button @click="onBooking" class="contact-btn request-btn">Book Your Session</button>

        <!-- Divider Line -->
        <!-- <hr class="divider" /> -->

        <!-- Extra Buttons
        <button @click="onSubscribe" class="contact-btn subscribe-btn">Subscribe</button>
        <button @click="onTip" class="contact-btn tip-btn">Tip</button> -->
      </div>  
        <!-- Modal -->
        <div v-if="isModalVisible" class="modal">
          <ReserveYourBooking           
          :psychic="psychic"
          :closeModal="closeModal"
          @booking-submitted="closeModal" />
        </div>

        <div v-if="isPrivateMessageModalVisible" class="modal">
          <SendPrivateMessage           
          :psychic="psychic"
          :closeModal="closePrivateMessageModal"
          />
        </div>

        <div v-if="isRequestReadingModalVisible" class="modal">
          <MakeReadingRequest           
          :psychic="psychic"
          :closeModal="closeRequestReadingModal"
          />
        </div>
    </div>
  </div>
</template>

<script>
import ReserveYourBooking from './ReserveYourBooking.vue'; // Adjust the path if necessary
import SendPrivateMessage from './SendPrivateMessage.vue';
import MakeReadingRequest from './MakeReadingRequest.vue';

export default {
  components: {
    ReserveYourBooking,
    SendPrivateMessage,
    MakeReadingRequest
  },
  props: {
    psychic: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      touchStartX: 0,
      touchEndX: 0,
      placeholderImage: require('@/assets/openpsychiclogo.png'),
      isModalVisible: false,
      isPrivateMessageModalVisible: false,
      isRequestReadingModalVisible: false,
    };
  },
  methods: {
    handleTouchStart(event) {
      this.touchStartX = event.changedTouches[0].screenX;
    },
    handleTouchEnd(event) {
      this.touchEndX = event.changedTouches[0].screenX;
      this.detectSwipe();
    },
    detectSwipe() {
      if (this.touchEndX > this.touchStartX + 50) {
        this.goBack(); // Swipe right action
      }
    },
    goBack() {
      this.$emit('back');
    },
    handleImageError(event) {
      event.target.src = this.placeholderImage;
    },
    onRequest() {
      this.isRequestReadingModalVisible = true; // Show the modal when "Book Your Session" is clicked
    },
    onBooking() {
      this.isModalVisible = true; // Show the modal when "Book Your Session" is clicked
    },
    onMessage() {
      // const psychic = this.psychic; // Assuming this.psychic is the entire object
      // this.$store.dispatch('navigateToMessages', psychic);
      this.isPrivateMessageModalVisible = true; // Show the modal when "Book Your Session" is clicked
    },
    closeModal() {
      this.isModalVisible = false; // Close the modal when the close button is clicked
    },
    closePrivateMessageModal() {
      this.isPrivateMessageModalVisible = false; // Close the modal when the close button is clicked
    },
    closeRequestReadingModal() {
      this.isRequestReadingModalVisible = false; // Close the modal when the close button is clicked
    },
  },
};
</script>

<style scoped>
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.swipe-container {
  touch-action: pan-y;
}

.page-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}

.page-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar Chrome, Safari, Opera */
}

.psychic-profile-card {
  width: 100%;
  background-color: #2a093a;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  overflow: hidden;
}

.profile-header {
  position: relative;
  
}

.background-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.profile-info {
  position: absolute;
  top: 320px;
  left: 50%;
  transform: translateX(-50%);
}

.profile-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 2px solid #F2D1FF;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.name {
  font-size: 1.8em;
  font-weight: 700;
  margin: 20px 0 5px;
  color: #ECC3FF;
}

.username {
  font-size: 1em;
  color: #F2D1FF;
  font-weight: 600;
  margin-bottom: 8px;
}

.profile-body {
  padding: 20px;
  color: #D1A0F1;
}

.bio {
  margin: 10px 0;
  font-style: italic;
  color: #D1A0F1;
}

.contact-module button:hover {
  background-color: #5a0ca7;
}

.contact-module button:active {
  transform: scale(0.98);
}

.contact-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 360px;
  margin: 15px auto;
  padding: 15px;
  background-color: #3f1e4e;
  border-radius: 12px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.contact-card h3 {
  font-size: 1.8em;
  color: #ECC3FF;
  margin-bottom: 20px;
}

.contact-btn {
  color:aliceblue;
  background-color: #16041E ;
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  font-size: 1em;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.1s;
}

.contact-btn:hover {
  transform: scale(1.02);
  opacity: 0.9;
}

.divider {
  width: 90%;
  height: 2px;
  background-color: #6a0dad;
  margin: 20px 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .profile-info {
    top: 250px; /* Adjust top position for medium-sized screens */
  }
}

@media (max-width: 480px) {
  .profile-info {
    top: 180px; /* Adjust top position further for smaller screens */
  }
}

/* Responsive Design */
@media (max-width: 600px) {
  .profile-image {
    width: 90px;
    height: 90px;
  }

  .name {
    font-size: 1.4em;
  }

  .contact-module {
    padding: 12px;
  }

  .background-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}
</style>
